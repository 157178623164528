import React, { Component } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { companyActions } from '../../actions/companyActions';
import { encodeStringForURL } from '../../Utilities';

class CreditNotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalCredit: 0,
            latestCreditIssueDate: ""
        }
    }

    componentDidMount() {
        const companyId = this.props.company.id;

        this.props.getCreditNoteTotal(companyId)
            .then((result) => {
                this.setState({ totalCredit: result.data });
            })
            .catch(() => { });

        this.props.getLatestCreditIssueDate(companyId)
            .then((result) => {
                const dateString = result.data !== "" ? result.data : "N/A";
                this.setState({ latestCreditIssueDate: dateString });
            })
            .catch(() => { });
    }

    render() {
        const { totalCredit, latestCreditIssueDate } = this.state;
        const companyNameEncoded = encodeStringForURL(this.props.company.name.toLowerCase());

        const sections = [
            {
                title: 'View Credit Notes',
                description: 'View credit notes that have previously been created',
                link: `/companiesforcredit/${companyNameEncoded}/creditactions/creditnotes/view`,
                icon: 'Images/view-credit-notes.svg'
            },
            {
                title: 'Create Credit Note',
                description: 'Create a new credit note for the company',
                link: `/companiesforcredit/${companyNameEncoded}/creditactions/creditnotes/create`,
                icon: 'Images/create-credit-note.svg'
            }
        ];

        return (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="link" to={'/companiesforcredit'}>Credit Actions Company Lookup</Link>&nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />&nbsp;
                        <Link className="link" to={`/companiesforcredit/${companyNameEncoded}/creditactions`}>Credit Actions</Link>&nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />&nbsp;{this.props.company.name} Credit Notes
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h2 className="d-inline-block mt-4">{this.props.company.name} Credit Notes</h2>
                    </Col>
                    <Col md={4} className='mb-4 mt-4'>
                        <Card className='p-3 m-0'>
                            <Row className='mt-0'>
                                <Col sm={10}>
                                    <h5 className="d-block">Credit Note Total</h5>
                                    <h2 className="d-block m-0"><strong>£{totalCredit.toFixed(2)}</strong></h2>
                                    <p className="d-block mb-0">Last credit note issued: {latestCreditIssueDate}</p></Col>
                            </Row>
                        </Card>
                    </Col>
                    {sections.map((s, i) => {
                        return (
                            <Col md={4} key={i} className='mb-4 mt-4'>
                                <Link to={s.link} className='text-decoration-none'>
                                    <Card className='p-2'>
                                        <Row className='mt-2'>
                                            <Col sm={3}><Image src={s.icon} className="d-inline-block card-icon mt-2" /></Col>
                                            <Col sm={9}><h5 className="d-inline-block">{s.title}</h5>
                                                <p className="credit-action-card">{s.description}</p></Col>
                                        </Row>
                                    </Card>
                                </Link>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company
    }
}

const mapDispatchToProps = {
    getCreditNoteTotal: companyActions.getCreditNoteTotal,
    getLatestCreditIssueDate: companyActions.getLatestCreditIssueDate
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotes)