import * as actionType from '../actionTypes/companyActionTypes';

const initState = {
    code: '',
    currentVerificationStep: 0,
    error: '',
    verifiedCompany: {},
    verifiedCompanyTimeout: null,

    company: {
        id: null,
        isAuth: false,
        name: '',
        createdDate: null,
        lastModified: null,
        totalCredit: null,
        latestCreditIssueDate: null
    },
    companyUser: {
        email: '',
        forename: '',
        surname: ''
    },
    companyBillingAddress: {
        addressLine1: '',
        addressLine2: '',
        town: '',
        country: '',
        county: '',
        postcode: ''
    },
    companyCardDetails: {
        holderName: '',
        cardNumber: '',
        status: null
    }
}

const companyReducer = (state = initState, action) => {
    switch (action.type) {
        case actionType.COMPANY_GET_ALL_FAILURE:
        case actionType.COMPANY_GET_BY_ID_FAILURE:
        case actionType.COMPANY_SEND_CODE_FAILURE:
        case actionType.COMPANY_GET_BILLING_ADDRESS_FAILURE:
        case actionType.COMPANY_GET_CARD_DETAILS_FAILURE:
        case actionType.COMPANY_UPDATE_NAME_FAILURE:
        case actionType.COMPANY_UPDATE_EMAIL_FAILURE:
        case actionType.COMPANY_GET_PROCESSING_ORDERS_FAILURE:
        case actionType.COMPANY_GET_PROCESSED_ORDERS_FAILURE:
        case actionType.COMPANY_GET_FAILED_ORDERS_FAILURE:
        case actionType.GET_INVOICES_FAILURE:
        case actionType.CREATE_ADHOC_CHARGE_FAILURE:
        case actionType.CREATE_CREDIT_NOTE_FAILURE:
        case actionType.GET_LAST_CREDIT_ISSUE_DATE_FAILURE:
            return { ...state, error: action.error }

        case actionType.COMPANY_GET_ALL:
        case actionType.COMPANY_GET_ALL_SUCCESS:
        case actionType.COMPANY_GET_BY_ID:
        case actionType.COMPANY_SEND_CODE:
        case actionType.COMPANY_GET_BILLING_ADDRESS:
        case actionType.COMPANY_GET_CARD_DETAILS:
        case actionType.COMPANY_UPDATE_NAME:
        case actionType.COMPANY_UPDATE_EMAIL:
        case actionType.COMPANY_GET_PROCESSING_ORDERS:
        case actionType.COMPANY_GET_PROCESSED_ORDERS:
        case actionType.COMPANY_GET_FAILED_ORDERS:
        case actionType.GET_LAST_CREDIT_ISSUE_DATE:
        case actionType.GET_INVOICES:
            return { ...state }

        case actionType.CREATE_ADHOC_CHARGE:
        case actionType.CREATE_CREDIT_NOTE:
            return {
                ...state
            }
        case actionType.SET_VERIFIED_COMPANY_TIMER:
            return {
                ...state,
                verifiedCompanyTimeout: action.expiry
            }
        case actionType.SET_VERIFIED_COMPANY_DETAILS:
            return {
                ...state,
                verifiedCompany: state.company
            }
        case actionType.RESET_VERIFIED_COMPANY_TIMER:
            return {
                ...state,
                verifiedCompanyTimeout: null
            }
        case actionType.COMPANY_RESET_COMPANY:
            return {
                ...state,
                company: initState.company
            }
        case actionType.COMPANY_GET_BY_ID_SUCCESS:
            return {
                ...state,
                error: '',
                company: {
                    ...state.company,
                    ...action.company
                }
            }
        case actionType.COMPANY_SEND_CODE_SUCCESS:
            return {
                ...state,
                error: '',
                code: action.code
            }
        case actionType.COMPANY_GET_BILLING_ADDRESS_SUCCESS:
            return {
                ...state,
                error: '',
                companyBillingAddress: {
                    id: action.address.id,
                    addressLine1: action.address.line1,
                    addressLine2: action.address.line2,
                    town: action.address.town,
                    country: action.address.country,
                    county: action.address.county,
                    postcode: action.address.postcode,
                }
            }
        case actionType.COMPANY_UPDATE_NAME_SUCCESS:
            return {
                ...state,
                error: '',
                company: {
                    ...state.company,
                    ...action.company
                }
            }
        case actionType.COMPANY_UPDATE_EMAIL_SUCCESS:
            return {
                ...state,
                error: '',
                companyUser: { ...action.user }
            }
        case actionType.COMPANY_SET_CODE:
            return {
                ...state,
                code: action.code
            }
        case actionType.COMPANY_SET_COMPANY:
            return {
                ...state,
                company: {
                    ...state.company,
                    ...action.company
                }
            }
        case actionType.COMPANY_SET_USER:
            return {
                ...state,
                companyUser: action.user
            }
        case actionType.COMPANY_SET_CURRENT_VERIFICATION_STEP:
            return {
                ...state,
                currentVerificationStep: action.step
            }
        case actionType.COMPANY_VERIFY_CODE_SUCCESS:
            return {
                ...state,
                company: {
                    ...state.company,
                    isAuth: true
                }
            }
        case actionType.GET_CREDIT_NOTE_TOTAL_SUCCESS:
            return {
                ...state,
                company: {
                    ...state.company,
                    totalCredit: action.totalCredit
                }
            }
        case actionType.GET_LAST_CREDIT_ISSUE_DATE_SUCCESS:
            return {
                ...state, 
                company: {
                    ...state.company,
                    latestCreditIssueDate: action.issueDate
                }
            }
        case actionType.CREATE_CREDIT_NOTE_SUCCESS:
        case actionType.COMPANY_GET_PROCESSED_ORDERS_SUCCESS:
        case actionType.COMPANY_GET_FAILED_ORDERS_SUCCESS:
        case actionType.COMPANY_GET_PROCESSING_ORDERS_SUCCESS:
        case actionType.CREATE_ADHOC_CHARGE_SUCCESS:
        case actionType.GET_INVOICES_SUCCESS:
            return {
                ...state,
                error: ''
            }
        default:
            return state;
    }
}

export default companyReducer;