import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormValidator from '../../common/FormValidator';
import { Form, FormGroup, FormLabel, FormControl, Container, Button, Row, Col, Card, Image } from 'react-bootstrap';
import { extractData } from '../../../Consumer';
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { userActions } from '../../../actions/userActions';

const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN;
const strongPasswordPattern = /^(?=.*[a-z])(?=.*[0-9])(?=.*[#$^+=!*\(\)@%&\"'\[\]{},_\\\/;:`\-~<>]).{6,}/;
const numberPattern = /^(?=.*[0-9])/;
const specialCharPattern = /^(?=.*[#$^+=!*\(\)@%&\"'\[\]{},_\\\/;:`\-~<>])/;
const lengthPattern = /^.{6,}/;

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'password',
                method: 'matches',
                args: [strongPasswordPattern],
                validWhen: true,
                message: 'Password must contain at least one number, one special character and at least 6 characters long'
            },
            {
                field: 'confirmPassword',
                method: 'isEmpty',
                validWhen: false,
                message: 'Confirm Password is required'
            }
        ]);

        this.state = {
            form: {
                operatorUserID: null,
                userID: '',
                password: '',
                confirmPassword: ''
            },
            passwordResetRequestID: this.props.match.params.id || null,
            passwordResetRequest: '',
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            passwordNumberCheck: false,
            passwordSpecialCharCheck: false,
            passwordLengthCheck: false,
            recaptcha: false,
            recaptchaError: false,
            validation: this.validator.valid(),
            error: false,
            message: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleShowHidePassword = this.handleShowHidePassword.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.getPasswordResetRequest = this.getPasswordResetRequest.bind(this);
    }

    componentDidMount() {
        this.getPasswordResetRequest();
    }

    getPasswordResetRequest() {
        let { passwordResetRequestID } = this.state;

        this.props.getPasswordResetRequest(passwordResetRequestID)
            .then((result) => {
                this.setState({ error: false, passwordResetRequest: extractData(result) });
            })
            .catch(() => {
                this.props.history.push('/')
            });
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleInputChange(e) {
        let { name, value } = e.target;

        if (name === "password") {
            var numberPass = numberPattern.test(value);
            var specialCharPass = specialCharPattern.test(value);
            var lengthPass = lengthPattern.test(value);

            this.setState(prevState => ({
                passwordNumberCheck: numberPass,
                passwordSpecialCharCheck: specialCharPass,
                passwordLengthCheck: lengthPass,
                registration: {
                    ...prevState.registration,
                }
            }));
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleRecaptcha = () => {
        this.setState({ recaptcha: true });
    }

    handleSubmit(event) {
        event.preventDefault();

        let { form, recaptcha } = this.state;

        // TODO: Remove when Confirm Password is stripped from the entire application. 
        form.confirmPassword = form.password;

        const validation = this.validator.validate(form);

        this.setState({
            validation: validation,
            recaptchaError: !recaptcha
        });

        this.submitted = true;

        if (validation.isValid && recaptcha) {
            this.props.changePassword(form)
                .then(() => {
                    this.setState({ error: false });
                    this.props.history.push({ pathname: "/account/password/result", state: { changed: true, email: '' } });
                })
                .catch((error) => {
                    this.setState({
                        error: true,
                        message: error.message
                    });
                });
        }
    }

    renderForm(form) {
        let validation = this.submitted ?
            this.validator.validate(form) :
            this.state.validation;

        let { loading, error, message, passwordResetRequest, passwordNumberCheck, passwordSpecialCharCheck, passwordLengthCheck, recaptchaError } = this.state;

        return (
            <Row className="justify-content-center row-form-adjustedheight">
                <Col md={6} className="align-self-center">
                    <Card className="p-5">
                        <Image src='Images/logo.png' className="mx-auto" width="220px" />
                        <h5 className="mb-4 mt-3 text-center">Customer Service Portal</h5>
                        <h5 className="mb-3">Password Reset</h5>
                        <Form onSubmit={this.handleSubmit}>
                            {error && <Card className="card-danger mb-3">
                                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                                <Card.Body>{message}</Card.Body>
                            </Card>}
                            <input type="hidden" name="userID" value={passwordResetRequest.userID} />

                            <FormGroup>
                                <FormLabel htmlFor="passwordField" className="font-weight-bold">New password</FormLabel>
                                <FormControl disabled={loading} id="passwordField" type={this.state.passwordInputType} name="password" value={form.password} onChange={this.handleInputChange} />
                                <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={this.state.passwordInputIcon} /></span>
                                <span className="text-danger">{validation.password.message}</span>
                            </FormGroup>

                            <div className="form-password-checklist">
                                <ul>
                                    <li className={passwordNumberCheck ? "pass" : "fail"}>One number</li>
                                    <li className={passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
                                    <li className={passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                                </ul>
                            </div>

                            <Container className="d-flex justify-content-center my-4">
                                <ReCAPTCHA sitekey={captchaKey} onChange={this.handleRecaptcha} />
                            </Container>
                            {recaptchaError && <p className="text-danger">Please complete captcha</p>}

                            <FormGroup>
                                <Button size="lg" type="submit" value="Submit" block>Continue to login screen</Button>
                            </FormGroup>
                        </Form>
                    </Card>
                </Col>
            </Row>
        );
    }

    render() {
        const { form, passwordResetRequest } = this.state;
        form.userID = passwordResetRequest.userID;

        const contents = this.renderForm(form);

        return (
            <Container className="h-100">
                {contents}
            </Container>
        );
    }
}

const mapDispatchToProps = {
    changePassword: userActions.changePassword,
    getPasswordResetRequest: userActions.getPasswordResetRequest
}

export default connect(null, mapDispatchToProps)(ResetPassword)