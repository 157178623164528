import React, { Component } from 'react';
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import FormValidator from '../common/FormValidator';
import { ErrorAlert, SuccessAlert } from '../common/Alerts';
import LoadingBar from '../common/LoadingBar';

import { companyActions } from '../../actions/companyActions';
import { encodeStringForURL } from '../../Utilities';


class AdditionalCosts extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'category',
                method: 'isEmpty',
                validWhen: false,
                message: 'Category is required'
            },
            {
                field: 'invoiceAmount',
                method: 'isEmpty',
                validWhen: false,
                message: 'Invoice Amount is required'
            },
            {
                field: 'invoiceAmount',
                method: 'isCurrency',
                validWhen: true,
                message: 'Invoice Amount is invalid'
            },
            {
                field: 'reason',
                method: 'isEmpty',
                validWhen: false,
                message: 'Reason for Additional Cost is required'
            }
        ]);

        this.state = {
            additionalCost: {
                category: "",
                companyEmail: this.props.companyUser.email,
                companyId: this.props.company.id,
                companyName: this.props.company.name,
                customerServiceAgent: `${this.props.user.forename} ${this.props.user.surname}`,
                invoiceAmount: "",
                orderNumber: "",
                other: "",
                reason: "",
            },
            confirmed: false,
            created: false,
            error: false,
            loading: false,
            message: "",
            otherMessage: "",
            orderNumberError: false,
            showOther: false,
            submitted: false,
            validation: this.validator.valid()
        }
    }

    getCategoryText = () => {
        const category = this.state.additionalCost.category;

        switch (category) {
            case "1": return "Inbound Stock Issues";
            case "2": return "Returns Resolution";
            case "3": return "Stock Management";
            case "4": return "Other";
            default: return null;
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const newAdditionalCost = { ...this.state.additionalCost };
        newAdditionalCost[name] = value;

        if (e.target.name === "category") {
            if (value === "4") {
                this.setState({ showOther: true });
            } else {
                this.setState({ showOther: false, other: "" });
            }
        }

        this.setState(prevState => ({
            ...prevState,
            additionalCost: newAdditionalCost
        }));
    }

    cancel = () => {
        this.props.history.push(`/companiesforcredit/${encodeStringForURL(this.props.company.name.toLowerCase())}/creditactions`);
    }

    submit = (e) => {
        e.preventDefault();

        const cost = this.state.additionalCost;
        cost.reason = cost.reason.replace(/(<([^>]+)>)/ig, "");

        const validation = this.validator.validate(cost);
        this.setState({ validation: validation });

        if (cost.orderNumber !== "") {
            this.props.checkOrderWithCustomerReferenceExists(this.props.company.id, cost.orderNumber)
                .then((exists) => {
                    if (!exists) {
                        this.setState({ orderNumberError: true });
                        return;
                    } else {
                        if (cost.category === "4" && cost.other === "") {
                            this.setState({ otherMessage: "Other category information is required" });
                            return;
                        } else {
                            this.setState({ otherMessage: "" });
                        }

                        if (validation.isValid)
                            this.setState({ confirmed: true, orderNumberError: false });
                    }
                })
                .catch(() => { });
        } else {
            if (cost.category === "4" && cost.other === "") {
                this.setState({ otherMessage: "Other category information is required" });
                return;
            } else {
                this.setState({ otherMessage: "" });
            }

            if (validation.isValid)
                this.setState({ confirmed: true, orderNumberError: false });
        }
    }

    back = () => {
        this.setState({ confirmed: false });
    }

    confirm = () => {
        this.setState({ loading: true });
        const additionalCost = this.state.additionalCost;

        this.props.createAdHocCharge(additionalCost)
            .then((result) => {
                this.setState({
                    created: true,
                    error: false,
                    loading: false,
                    message: result.data
                });
            })
            .catch(() => {
                this.setState({
                    created: false,
                    error: true,
                    loading: false
                });
            });
    }

    return = () => {
        this.props.history.push(`/companiesforcredit/${encodeStringForURL(this.props.company.name.toLowerCase())}/creditactions`);
    }

    render() {
        const { additionalCost, confirmed, created, error, loading, message, otherMessage, orderNumberError, showOther, validation } = this.state;
        const types = [
            { name: "Inbound Stock Issues", value: "1" },
            { name: "Returns Resolution", value: "2" },
            { name: "Stock Management", value: "3" },
            { name: "Other", value: "4" }];

        return (
            <Container fluid>
                <Row>
                    <Col md={5}>
                        <h2 className="mb-4">Additional Costs</h2>

                        {error ? <React.Fragment >
                            <ErrorAlert title="There seems to be a problem" message="This invoice has been generated, but the company does not have a valid card stored in the Selazar platform. Once the company updates their card details, they will be charged accordingly." />
                            <Button className="btn btn-primary float-right" onClick={this.return}>Return to Company Profile</Button>
                        </React.Fragment> :

                            created ?
                                <React.Fragment >
                                    <SuccessAlert title="Success" message={message} />
                                    <Button className="btn btn-primary float-right" onClick={this.return}>Return to Company Profile</Button>
                                </React.Fragment> :

                                <React.Fragment>
                                    <div className="form-input-description">
                                        <p className="mb-4">Once you have generated an invoice finance will be informed via email. Be careful to check all information as the customer will automatically be charged.</p>
                                    </div>
                                    {loading ? <LoadingBar /> :
                                        <Form>
                                            {orderNumberError && <React.Fragment >
                                                <ErrorAlert title="There seems to be a problem" message="An order with this order number does not exist." />
                                            </React.Fragment>}

                                            <fieldset>
                                                <FormGroup>
                                                    <FormLabel htmlFor="category"><strong>Category</strong></FormLabel>
                                                    {confirmed === true ? <p>{this.getCategoryText(additionalCost.category)}</p> :
                                                        <FormControl id="category" name="category" as="select" value={additionalCost.category} onChange={this.handleChange}>
                                                            <option key="" value="">Please select...</option>
                                                            {types.map(type => (<option key={type.value} value={type.value}>{type.name}</option>))}
                                                        </FormControl>}
                                                    <span className="text-danger">{validation.category.message}</span>
                                                </FormGroup>

                                                {showOther && <FormGroup>
                                                    <FormLabel htmlFor="other"><strong>Other - What category was the additional cost for?</strong></FormLabel>
                                                    {confirmed ? <p>{additionalCost.other}</p> :
                                                        <React.Fragment>
                                                            <FormControl id="other" type="text" name="other" value={additionalCost.other} onChange={this.handleChange} />
                                                            <span className="text-danger">{otherMessage}</span></React.Fragment>}
                                                </FormGroup>}

                                                <Col md={6} className="p-0 m-0">
                                                    <FormGroup>
                                                        <FormLabel htmlFor="invoiceAmount"><strong>Invoice Amount (£)</strong></FormLabel>
                                                        {confirmed ? <p>£{additionalCost.invoiceAmount}</p> :
                                                            <FormControl id="invoiceAmount" type="number" name="invoiceAmount" value={additionalCost.invoiceAmount} onChange={this.handleChange}
                                                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />}
                                                        <span className="text-danger">{validation.invoiceAmount.message}</span>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <FormLabel htmlFor="orderNumber"><strong>Order Number</strong>
                                                            {!confirmed ? <small><i> - optional</i></small> : null}
                                                        </FormLabel>
                                                        {confirmed ? <p>{additionalCost.orderNumber !== "" ? additionalCost.orderNumber : "N/A"}</p> :
                                                            <FormControl id="orderNumber" type="text" name="orderNumber" value={additionalCost.orderNumber} onChange={this.handleChange} />}
                                                    </FormGroup>
                                                </Col>

                                                <FormGroup>
                                                    <FormLabel htmlFor="reason"><strong>Reason for Additional Cost</strong></FormLabel>
                                                    {confirmed ? <p>{additionalCost.reason}</p> :
                                                        <textarea className="form-control" rows="4" id="reason" type="textarea" name="reason" value={additionalCost.reason} onChange={this.handleChange} />}
                                                    <span className="text-danger">{validation.reason.message}</span>
                                                </FormGroup>
                                            </fieldset>

                                            {confirmed ?
                                                <React.Fragment className="mt-5">
                                                    <Button className="float-left mt-4" variant="secondary" onClick={this.back}>Back</Button>
                                                    <Button className="float-right mt-4" onClick={this.confirm}>Confirm Additional Cost</Button>
                                                    <Button className="float-right mt-4" variant="link" onClick={this.cancel}>Cancel</Button>
                                                </React.Fragment> :

                                                <React.Fragment>
                                                    <Button className="float-right mt-4" onClick={this.submit}>Submit Additional Cost</Button>
                                                    <Button className="float-right mt-4" variant="link" onClick={this.cancel}>Cancel</Button>
                                                </React.Fragment>
                                            }
                                        </Form>
                                    }
                                </React.Fragment>
                        }
                    </Col>
                </Row>
            </Container >
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company,
        companyUser: state.companyReducer.companyUser,
        user: state.userReducer.user
    }
}

const mapDispatchToProps = {
    createAdHocCharge: companyActions.createAdHocCharge,
    checkOrderWithCustomerReferenceExists: companyActions.checkOrderWithCustomerReferenceExists
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCosts)