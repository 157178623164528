import { GET, PUT, POST } from '../Consumer';
import Endpoints from '../components/common/Endpoints';

import * as actionType from '../actionTypes/companyActionTypes';

export const companyActions = {
    checkOrderWithCustomerReferenceExists,
    createAdHocCharge,
    createCreditNote,
    getAllCompanies,
    getAccessForCompany,
    getTemporaryLoginDetails,
    getCreditNotes,
    getCompanyById,
    getCompanyBillingAddress,
    getCompanyCardDetails,
    getCompanyDirectDebitDetails,
    getAllCompaniesWithAccessData,
    getCourierTrackingUrlRoot,
    getLatestCreditIssueDate,
    getCreditNoteTotal,
    getOrderHistories,
    getOrderCosts,
    getInvoices,
    resetCompany,
    resetVerifiedCompantyTimeout,
    setCompany,
    setCurrentVerificationStep,
    sendVerificationCode,
    setVerificationCode,
    setVerifiedCompanyDetails,
    setVerifiedCompantyTimeout,
    setVerificationUser,
    updateBillingAddress,
    updateCompanyName,
    updateCompanyEmail,
    updateCompanyDirectDebitDetails,
    verifyCode
}

function getAllCompaniesWithAccessData(filter) {
    return async dispatch => {
        dispatch(request());

        try {
            let url = new URL(Endpoints.COMPANY.GET.ALL_WITH_ACCESS);
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));

            const response = await GET(url);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get companies with access status failed");
            }

            dispatch(success());
            return result;
        } catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request() { return { type: actionType.COMPANY_GET_ALL_WITH_ACCESS_STATUS } }
        function success(companies) { return { type: actionType.COMPANY_GET_ALL_WITH_ACCESS_STATUS_SUCCESS, companies } }
        function failure(error) { return { type: actionType.COMPANY_GET_ALL_WITH_ACCESS_STATUS_FAILURE, error } }
    }
}

function getAllCompanies(filter, pageIndex) {
    return async dispatch => {
        dispatch(request());

        try {
            let url = new URL(Endpoints.COMPANY.GET.ALL);

            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
            url.searchParams.append("pageIndex", pageIndex);

            const response = await GET(url);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get companies failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request() { return { type: actionType.COMPANY_GET_ALL } }
        function success(companies) { return { type: actionType.COMPANY_GET_ALL_SUCCESS, companies } }
        function failure(error) { return { type: actionType.COMPANY_GET_ALL_FAILURE, error } }
    }
}

function getAccessForCompany(id) {
    return async dispatch => {
        dispatch(request());
        try {
            const response = await GET(Endpoints.COMPANY.GET.ACCESS + id);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get access for company failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request() { return { type: actionType.COMPANY_GET_ACCESS } }
        function success(access) { return { type: actionType.COMPANY_GET_ACCESS_SUCCESS, access } }
        function failure(error) { return { type: actionType.COMPANY_GET_ACCESS_FAILURE, error } }
    }
}

function getTemporaryLoginDetails(id) {
    return async dispatch => {
        dispatch(request());
        try {
            const response = await POST(Endpoints.COMPANY.POST.TEMPORARY_LOGIN_DETAILS + id);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get temporary login details failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request() { return { type: actionType.COMPANY_GET_TEMPORARY_LOGIN } }
        function success(access) { return { type: actionType.COMPANY_GET_TEMPORARY_LOGIN_SUCCESS, access } }
        function failure(error) { return { type: actionType.COMPANY_GET_TEMPORARY_LOGIN_FAILURE, error } }
    }
}

function getCompanyById(id) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await GET(Endpoints.COMPANY.GET.BY_ID + id);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get company failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request() { return { type: actionType.COMPANY_GET_BY_ID } }
        function success(company) { return { type: actionType.COMPANY_GET_BY_ID_SUCCESS, company } }
        function failure(error) { return { type: actionType.COMPANY_GET_BY_ID_FAILURE, error } }
    }
}

function getCompanyBillingAddress(id) {
    return async dispatch => {
        dispatch(request(id));

        try {
            const response = await GET(Endpoints.ADDRESS.GET.COMPANY_BILLING + id);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get company billing address failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request(id) { return { type: actionType.COMPANY_GET_BILLING_ADDRESS, id } }
        function success(address) { return { type: actionType.COMPANY_GET_BILLING_ADDRESS_SUCCESS, address } }
        function failure(error) { return { type: actionType.COMPANY_GET_BILLING_ADDRESS_FAILURE, error } }
    }
}

function getCompanyCardDetails(id) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await GET(Endpoints.FINANCE.GET.CARD_DETAILS + id);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get company card details failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request() { return { type: actionType.COMPANY_GET_CARD_DETAILS } }
        function success(card) { return { type: actionType.COMPANY_GET_CARD_DETAILS_SUCCESS, card } }
        function failure(error) { return { type: actionType.COMPANY_GET_CARD_DETAILS_FAILURE, error } }
    }
}

function getCompanyDirectDebitDetails(id) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await GET(Endpoints.FINANCE.GET.DIRECT_DEBIT + id);
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get company direct debit details failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }

        function request() { return { type: actionType.COMPANY_GET_DIRECT_DEBIT } }
        function success(debit) { return { type: actionType.COMPANY_GET_DIRECT_DEBIT_SUCCESS, debit } }
        function failure(error) { return { type: actionType.COMPANY_GET_DIRECT_DEBIT_FAILURE, error } }
    }
}

function setVerifiedCompantyTimeout() {
    // 5 minutes from current time.
    const expiry = new Date().getTime() + 300000;

    return async dispatch => {
        dispatch(request(expiry));

        setTimeout(() => {
            dispatch(resetRequest());
        }, 300000);
    }

    function request(expiry) { return { type: actionType.SET_VERIFIED_COMPANY_TIMER, expiry } }
    function resetRequest() { return { type: actionType.RESET_VERIFIED_COMPANY_TIMER } }
}

function resetVerifiedCompantyTimeout() {
    return async dispatch => {
        dispatch(request());
    }

    function request() { return { type: actionType.RESET_VERIFIED_COMPANY_TIMER } }
}

function setCompany(company) {
    return async dispatch => {
        dispatch(request(company));
    }

    function request(company) { return { type: actionType.COMPANY_SET_COMPANY, company: company } }
}

function resetCompany() {
    return async dispatch => {
        dispatch(request());
    }

    function request() { return { type: actionType.COMPANY_RESET_COMPANY } }
}

function setVerifiedCompanyDetails() {
    return async dispatch => {
        dispatch(request());
    }

    function request() { return { type: actionType.SET_VERIFIED_COMPANY_DETAILS } }
}

function setCurrentVerificationStep(step) {
    return async dispatch => {
        dispatch(request(step));
    }

    function request(step) { return { type: actionType.COMPANY_SET_CURRENT_VERIFICATION_STEP, step } }
}

function setVerificationCode(code) {
    return async dispatch => {
        dispatch(request({ code }));
    }

    function request(code) { return { type: actionType.COMPANY_SET_CODE, code } }
}

function setVerificationUser(user) {
    return async dispatch => {
        dispatch(request({
            id: user.id,
            email: user.email,
            forename: user.forename,
            surname: user.surname
        }));
    }

    function request(user) { return { type: actionType.COMPANY_SET_USER, user } }
}

function sendVerificationCode(id, contactEmail) {
    return async dispatch => {
        dispatch(request({ contactEmail }));

        try {
            const response = await POST(Endpoints.COMPANY.POST.SEND_CODE, { Email: contactEmail, CompanyID: id });
            const result = await response.json();
            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Sending verification code failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(email) { return { type: actionType.COMPANY_SEND_CODE, email } }
    function success(code) { return { type: actionType.COMPANY_SEND_CODE_SUCCESS, code } }
    function failure(error) { return { type: actionType.COMPANY_SEND_CODE_FAILURE, error } }
}

function verifyCode(code, id) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await POST(Endpoints.COMPANY.POST.VERIFY_CODE, { Code: code, CompanyID: id });
            const result = await response.json();
            if (result.error || result.data === false) {
                throw result.message ? new Error(result.message) : new Error("Code verification failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(code) { return { type: actionType.COMPANY_VERIFY_CODE, code } }
    function success(code) { return { type: actionType.COMPANY_VERIFY_CODE_SUCCESS, code } }
    function failure(error) { return { type: actionType.COMPANY_VERIFY_CODE_FAILURE, error } }
}

function updateCompanyName(id, name, email) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await PUT(Endpoints.COMPANY.PUT.UPDATE_COMPANY_NAME, { CompanyID: id, name: name, email: email });
            const result = await response.json();
            if (result.error || result.data === false) {
                throw result.message ? new Error(result.message) : new Error("Name update failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(name) { return { type: actionType.COMPANY_UPDATE_NAME, name } }
    function success(company) { return { type: actionType.COMPANY_UPDATE_NAME_SUCCESS, company } }
    function failure(error) { return { type: actionType.COMPANY_UPDATE_NAME_FAILURE, error } }
}

function updateCompanyEmail(id, initEmail, newEmail) {
    return async dispatch => {
        dispatch(request(newEmail));

        try {
            const response = await PUT(Endpoints.USER.PUT.UPDATE_EMAIL,
                {
                    companyId: id,
                    initEmail: initEmail,
                    newEmail: newEmail
                });
            const result = await response.json();
            if (result.error || result.data === false) {
                throw result.message ? new Error(result.message) : new Error("Email update failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(email) { return { type: actionType.COMPANY_UPDATE_EMAIL, email } }
    function success(user) { return { type: actionType.COMPANY_UPDATE_EMAIL_SUCCESS, user } }
    function failure(error) { return { type: actionType.COMPANY_UPDATE_EMAIL_FAILURE, error } }
}

function updateBillingAddress(id, email, line1, line2, town, country, county, postcode) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await PUT(Endpoints.ADDRESS.PUT.UPDATE_BILLING_ADDRESS,
                {
                    companyId: id,
                    email: email,
                    line1: line1,
                    line2: line2,
                    town: town,
                    country: country,
                    county: county,
                    postcode: postcode
                });
            const result = await response.json();
            if (result.error || result.data === false) {
                throw result.message ? new Error(result.message) : new Error("Billing address update failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(name) { return { type: actionType.COMPANY_GET_BILLING_ADDRESS, name } }
    function success(address) { return { type: actionType.COMPANY_GET_BILLING_ADDRESS_SUCCESS, address } }
    function failure(error) { return { type: actionType.COMPANY_GET_BILLING_ADDRESS_FAILURE, error } }
}

function updateCompanyDirectDebitDetails(model) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await PUT(Endpoints.FINANCE.PUT.DIRECT_DEBIT, model);
            const result = await response.json();
            if (result.error || result.data === false) {
                throw result.message ? new Error(result.message) : new Error("Direct debit details update failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request() { return { type: actionType.COMPANY_GET_DIRECT_DEBIT } }
    function success(debit) { return { type: actionType.COMPANY_GET_DIRECT_DEBIT_SUCCESS, debit } }
    function failure(error) { return { type: actionType.COMPANY_GET_DIRECT_DEBIT_FAILURE, error } }
}

function getOrderHistories(id, filter, orderType, pageIndex) {
    let url = "";
    let type = "";

    return async dispatch => {
        dispatch(request(id));
        try {
            switch (orderType) {
                case "processing": url = new URL(Endpoints.ORDER.GET.PROCESSING);
                    type = actionType.COMPANY_GET_PROCESSED_ORDERS;
                    break;
                case "completed": url = new URL(Endpoints.ORDER.GET.COMPLETED);
                    type = actionType.COMPANY_GET_PROCESSED_ORDERS;
                    break;
                case "failed": url = new URL(Endpoints.ORDER.GET.FAILED);
                    type = actionType.COMPANY_GET_FAILED_ORDERS;
                    break;
                default: throw new Error("No order type provided");
            }
            url.searchParams.append("companyID", id);
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
            url.searchParams.append("pageIndex", pageIndex)

            const response = await GET(url);
            const result = await response.json();

            if (result.error || result.data === false) {
                throw result.message ? new Error(result.message) : new Error("Get orders failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(id) { return { type: type, id } }
    function success(orders) { return { type: `${type}_SUCCESS`, orders } }
    function failure(error) { return { type: `${type}_FAILURE`, error } }
}

function getOrderCosts(id) {
    return async dispatch => {
        dispatch(request(id));

        try {
            const response = await GET(Endpoints.ORDER.GET.COSTS + id);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get order cost breakdown failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(id) { return { type: actionType.COMPANY_GET_ORDER_COSTS, id } }
    function success(costs) { return { type: actionType.COMPANY_GET_ORDER_COSTS_SUCCESS, costs } }
    function failure(error) { return { type: actionType.COMPANY_GET_ORDER_COSTS_FAILURE, error } }
}

function getCourierTrackingUrlRoot(id) {
    return async dispatch => {
        dispatch(request(id));

        try {
            const response = await GET(Endpoints.ORDER.GET.TRACKING_URL_ROOT + id);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get tracking URL root failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(id) { return { type: actionType.COMPANY_GET_TRACKING_URL_ROOT, id } }
    function success(url) { return { type: actionType.COMPANY_GET_TRACKING_URL_ROOT_SUCCESS, url } }
    function failure(error) { return { type: actionType.COMPANY_GET_TRACKING_URL_ROOT_FAILURE, error } }
}

function getInvoices(id, filter, pageIndex) {
    return async dispatch => {
        dispatch(request(id));

        try {
            const { paymentReference, invoiceType, dateFrom, dateTo, orderDescending } = filter;

            let url = new URL(Endpoints.INVOICE.GET.BY_ID);
            url.searchParams.append("id", id)

            if (paymentReference || invoiceType || dateFrom || dateTo || orderDescending) {
                Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
            }
            url.searchParams.append("pageIndex", pageIndex)

            const response = await GET(url);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Get invoices failed");
            }

            dispatch(success(result.data));
            return result.data;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(id) { return { type: actionType.GET_INVOICES, id } }
    function success(invoices) { return { type: actionType.GET_INVOICES_SUCCESS, invoices } }
    function failure(error) { return { type: actionType.GET_INVOICES_FAILURE, error } }
}

function checkOrderWithCustomerReferenceExists(companyId, customerReference) {
    return async dispatch => {

        try {
            let url = new URL(Endpoints.ORDER.GET.ORDER_WITH_CUSTOMER_REF);
            url.searchParams.append("companyId", companyId)
            url.searchParams.append("customerRefernece", customerReference)

            const response = await GET(url);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Order with this number does not exist.");
            }

            return result.data;
        }
        catch (error) {
            throw new Error(error.message);
        }
    }
}

function createAdHocCharge(additionalCost) {
    return async dispatch => {
        dispatch(request(additionalCost));

        try {
            const response = await POST(Endpoints.CHARGING.POST.CREATE_AD_HOC, additionalCost);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Additional Cost generation failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(additionalCost) { return { type: actionType.CREATE_ADHOC_CHARGE, additionalCost } }
    function success(additionalCost) { return { type: actionType.CREATE_ADHOC_CHARGE_SUCCESS, additionalCost } }
    function failure(error) { return { type: actionType.CREATE_ADHOC_CHARGE_FAILURE, error } }
}

function createCreditNote(creditNote) {
    return async dispatch => {
        dispatch(request(creditNote));

        try {
            const response = await POST(Endpoints.CREDIT.POST.CREATE_CREDIT_NOTE, creditNote);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Credit Note generation failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(creditNote) { return { type: actionType.CREATE_CREDIT_NOTE, creditNote } }
    function success(creditNote) { return { type: actionType.CREATE_CREDIT_NOTE_SUCCESS, creditNote } }
    function failure(error) { return { type: actionType.CREATE_CREDIT_NOTE_FAILURE, error } }
}

function getCreditNotes(companyId, filter, pageIndex) {
    return async dispatch => {
        dispatch(request(companyId));

        try {
            const { orderNumber, dateFrom, dateTo, orderDescending } = filter;

            let url = new URL(Endpoints.CREDIT.GET.ALL);
            url.searchParams.append("companyId", companyId);

            if (orderNumber || dateFrom || dateTo || orderDescending) {
                Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
            }
            url.searchParams.append("pageIndex", pageIndex)

            const response = await GET(url);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Fetching credit notes failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(companyId) { return { type: actionType.GET_CREDIT_NOTES, companyId } }
    function success(creditNotes) { return { type: actionType.GET_CREDIT_NOTES_SUCCESS, creditNotes } }
    function failure(error) { return { type: actionType.GET_CREDIT_NOTES_FAILURE, error } }
}

function getCreditNoteTotal(companyId) {
    return async dispatch => {
        dispatch(request(companyId));

        try {
            let url = new URL(Endpoints.CREDIT.GET.TOTAL_CREDIT);
            url.searchParams.append("companyId", companyId)

            const response = await GET(url);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Fetching total credit failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(companyId) { return { type: actionType.GET_CREDIT_NOTE_TOTAL, companyId } }
    function success(totalCredit) { return { type: actionType.GET_CREDIT_NOTE_TOTAL_SUCCESS, totalCredit } }
    function failure(error) { return { type: actionType.GET_CREDIT_NOTE_TOTAL_FAILURE, error } }
}

function getLatestCreditIssueDate(companyId) {
    return async dispatch => {
        dispatch(request(companyId));

        try {
            let url = new URL(Endpoints.CREDIT.GET.LAST_ISSUE_DATE);
            url.searchParams.append("companyId", companyId)

            const response = await GET(url);
            const result = await response.json();

            if (result.error) {
                throw result.message ? new Error(result.message) : new Error("Fetching last credit issue date failed");
            }

            dispatch(success(result.data));
            return result;
        }
        catch (error) {
            dispatch(failure(error.message));
            throw new Error(error.message);
        }
    }

    function request(companyId) { return { type: actionType.GET_LAST_CREDIT_ISSUE_DATE, companyId } }
    function success(issueDate) { return { type: actionType.GET_LAST_CREDIT_ISSUE_DATE_SUCCESS, issueDate } }
    function failure(error) { return { type: actionType.GET_LAST_CREDIT_ISSUE_DATE_FAILURE, error } }
}