import { findIndex, propEq } from 'ramda';

function sortStockItemsByDirection(array, key, direction) {
    return array.slice().sort((a, b) => {
        if (a.item[key] < b.item[key]) {
            return direction === 1 ? 1 : -1;
        }
        if (a.item[key] > b.item[key]) {
            return direction === 1 ? -1 : 1;
        }
        return 0;
    });
}

function sortValuesByDirection(array, key, direction) {
    return array.slice().sort((a, b) => {
        if (a[key] < b[key]) {
            return direction === 1 ? 1 : -1;
        }
        if (a[key] > b[key]) {
            return direction === 1 ? -1 : 1;
        }
        return 0;
    });
}

function getUser() {
    return JSON.parse(localStorage.getItem('user') || "null");
}

function isLoggedIn() {
    return (getUser() !== "null");
}

function toggleSortDirection(oldKey, direction, newKey) {
    if (oldKey !== newKey) {
        return { key: newKey, direction: 1 };
    }

    let newDirection = direction === 1 ? -1 : 1;
    return { key: newKey, direction: newDirection };
}

function authHeader() {
    // return authorization header with jwt token
    let user = getUser();

    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

function tokenisedFetch(url, init) {
    let reqInit = init || {};
    let headers = Object.assign({}, reqInit.headers, authHeader());

    return fetch(url, { ...reqInit, headers: headers });
}

async function tokenisedFetchAsync(url, init) {
    let reqInit = init || {};
    let headers = Object.assign({}, reqInit.headers, authHeader());

    return await fetch(url, { ...reqInit, headers: headers });
}

function hasPermission(feature) {
    const user = getUser();

    if (user) {
        if (user.permissions.indexOf(feature) >= 0) {
            return true;
        }
    }

    return false;
}

function checkPermission(feature, component) {
    const user = getUser();

    if (user) {
        if (user.permissions.indexOf(feature) >= 0) {
            return component;
        }
    }

    return null;
}

function checkProfile(profile, component) {
    const user = getUser();

    if (user) {
        if (user.profiles.indexOf(profile) >= 0) {
            return component;
        }
    }

    return null;
}

function hasProfile(profile) {
    const user = getUser();

    if (user) {
        if (user.profiles.indexOf(profile) >= 0) {
            return true;
        }
    }

    return false;
}

function isNullOrEmptyGuid(guid) {
    return guid === "00000000-0000-0000-0000-000000000000" || guid === null || guid === "" || guid === 0 || guid === "0" || guid === undefined;
}

function toLocalTimeString(dateString) {
    const date = new Date(dateString);
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString('en-GB');
}

const defaultGuid = "00000000-0000-0000-0000-000000000000";

const INTERACTION_SOURCE = {
    WEB: "353fa419-2638-4352-888d-f2750d25635e"

};

const PARTY = {
    RETAILER: '853a24d1-4c21-4360-a553-2bc5e7e9b92f',
    FULFILMENT_MANAGER: 'a2789e54-8b23-4060-84da-a51e9e395a1a',
    SUPPLIER: '5d0bd096-6b4f-48c7-a041-7e7d9d5512b7'
}

const findIndexByAttribute = (identifier, value, list) => {
    return findIndex(propEq(identifier, value))(list);
};

const updateItemPropInArray = (index, attrToUpdate, value, list) => {
    const updatedItem = {
        ...list[index],
        [attrToUpdate]: value
    };

    return [
        ...list.slice(0, index),
        updatedItem,
        ...list.slice(index + 1)
    ];
};
const encodeStringForURL = (component) => encodeURIComponent(component.replace("%", "%25"));
    
export {
    authHeader,
    isNullOrEmptyGuid,
    tokenisedFetch,
    tokenisedFetchAsync,
    hasPermission,
    checkPermission,
    sortStockItemsByDirection,
    sortValuesByDirection,
    toggleSortDirection,
    defaultGuid,
    isLoggedIn,
    checkProfile,
    hasProfile,
    findIndexByAttribute,
    updateItemPropInArray,
    INTERACTION_SOURCE,
    PARTY,
    toLocalTimeString,
    encodeStringForURL,
};