import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import FormValidator from '../../common/FormValidator';
import { ErrorAlert, SuccessAlert } from '../../common/Alerts';

import { userActions } from '../../../actions/userActions';
import { encodeStringForURL } from '../../../Utilities';

const strongPasswordPattern = /^(?=.*[a-z])(?=.*[0-9])(?=.*[#$^+=!*\(\)@%&\"'\[\]{},_\\\/;:`\-~<>]).{6,}/;
const numberPattern = /^(?=.*[0-9])/;
const specialCharPattern = /^(?=.*[#$^+=!*\(\)@%&\"'\[\]{},_\\\/;:`\-~<>])/;
const lengthPattern = /^.{6,}/;

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'password',
                method: 'matches',
                args: [strongPasswordPattern],
                validWhen: true
            },
            {
                field: 'confirmPassword',
                method: 'isEmpty',
                validWhen: false
            }
        ]);

        this.state = {
            form: {
                operatorUserID: null,
                userID: '',
                password: '',
                confirmPassword: ''
            },
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            passwordNumberCheck: false,
            passwordSpecialCharCheck: false,
            passwordLengthCheck: false,
            validation: this.validator.valid(),
            error: false,
            message: '',
            success: false
        };
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "password") {
            var numberPass = numberPattern.test(value);
            var specialCharPass = specialCharPattern.test(value);
            var lengthPass = lengthPattern.test(value);

            this.setState({
                passwordNumberCheck: numberPass,
                passwordSpecialCharCheck: specialCharPass,
                passwordLengthCheck: lengthPass,
            });
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const { form } = this.state;
        // TODO: Remove when Confirm Password is stripped from the entire application. 
        form.confirmPassword = form.password;

        const validation = this.validator.validate(form);
        this.setState({ validation: validation });

        this.submitted = true;

        if (validation.isValid) {
            this.props.changePassword(form)
                .then(() => {
                    this.setState({
                        error: false,
                        success: true,
                        title: "Success",
                        message: "Password has been changed successfully"
                    });
                })
                .catch((error) => {
                    this.setState({
                        error: true,
                        success: false,
                        title: "There seems to be a problem",
                        message: error.message
                    });
                });
        } else {
            this.setState({
                error: true,
                success: false,
                title: "There seems to be a problem",
                message: "Password does not meet the criteria, please try again"
            });
        }
    }

    renderForm = (form) => {
        const { error, loading, message, passwordLengthCheck, passwordNumberCheck, passwordSpecialCharCheck, title } = this.state;

        return (
            <Row>
                <Col md={5}>
                    <h2 className="block mb-4">Change Password</h2>
                    <Form onSubmit={this.handleSubmit}>
                        {error && <ErrorAlert title={title} message={message} />}

                        <FormGroup>
                            <FormLabel htmlFor="passwordField" className="font-weight-bold">New password</FormLabel>
                            <FormControl disabled={loading} id="passwordField" type={this.state.passwordInputType} name="password" value={form.password} onChange={this.handleInputChange} />
                            <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={this.state.passwordInputIcon} /></span>
                        </FormGroup>

                        <div className="form-password-checklist mb-3">
                            <ul>
                                <li className={passwordNumberCheck ? "pass" : "fail"}>One number</li>
                                <li className={passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
                                <li className={passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                            </ul>
                        </div>

                        <strong>Inform the customer of the following:</strong>
                        <span className="mt-2 d-block">
                            Your password must contain one number, one special character and be at least six characters long.
                        </span>
                        <span className="mt-2 d-block">
                            We will send a confirmation email explaining that your password has been changed.
                        </span>
                        <span className="mt-2 mb-4 d-block">
                            You can change your password from the portal at any time.
                        </span>

                        <FormGroup>
                            <Button type="submit" variant="primary float-right">Save New Password</Button>
                            <Link to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}/settings/security`}>
                                <Button variant="link float-right">Cancel</Button>
                            </Link>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        );
    }

    renderSucess() {
        const { message, title } = this.state;

        return (
            <Row>
                <Col md={5}>
                    <h2 className="block mb-4">Change Password</h2>
                    <SuccessAlert title={title} message={message} />

                    <strong>Inform the customer of the following:</strong>
                    <span className="mt-2 mb-4 d-block">
                        This password has been changed successfully. You will receive an
                        email to inform you that it has been changed.
                    </span>

                    <Link to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}`}>
                        <Button type="submit" variant="primary float-right">Return to Company Profile</Button>
                    </Link>
                </Col>
            </Row>
        )
    }

    render() {
        const { form, success } = this.state;
        form.userID = this.props.location.state.user.id;

        const renderChangePassword = this.renderForm(form);
        const renderSuccess = this.renderSucess();

        return (
            <Container fluid>
                {success ? renderSuccess : renderChangePassword}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.verifiedCompany
    }
}

const mapDispatchToProps = {
    changePassword: userActions.changePasswordForCustomerService
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)