import React, { Component } from 'react';
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import FormValidator from '../common/FormValidator';
import { ErrorAlert, SuccessAlert } from '../common/Alerts';
import LoadingBar from '../common/LoadingBar';

import { companyActions } from '../../actions/companyActions';

import { encodeStringForURL } from '../../Utilities';

class CreateCreditNote extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'amount',
                method: 'isEmpty',
                validWhen: false,
                message: 'Credit Amount is required'
            },
            {
                field: 'amount',
                method: 'isCurrency',
                validWhen: true,
                message: 'Credit Amount is invalid'
            },
            {
                field: 'orderNumber',
                method: 'isEmpty',
                validWhen: false,
                message: 'Order Number is required'
            },
            {
                field: 'notes',
                method: 'isEmpty',
                validWhen: false,
                message: 'Reason for Credit Note is required'
            }
        ]);

        this.state = {
            creditNote: {
                companyEmail: this.props.companyUser.email,
                companyId: this.props.company.id,
                companyName: this.props.company.name,
                customerServiceAgentName: `${this.props.user.forename} ${this.props.user.surname}`,
                amount: "",
                orderNumber: "",
                notes: "",
                totalCredit: this.props.company.totalCredit
            },
            confirmed: false,
            created: false,
            error: false,
            loading: false,
            message: "",
            orderNumberError: false,
            showOther: false,
            submitted: false,
            validation: this.validator.valid()
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const newCreditNote = { ...this.state.creditNote };

        newCreditNote[name] = value;

        this.setState(prevState => ({
            ...prevState,
            creditNote: newCreditNote
        }));
    }

    cancel = () => {
        this.props.history.push(`/companiesforcredit/${encodeStringForURL(this.props.company.name.toLowerCase())}/creditactions/creditnotes`);
    }

    submit = (e) => {
        e.preventDefault();

        const cost = this.state.creditNote;
        cost.notes = cost.notes.replace(/(<([^>]+)>)/ig, "");

        const validation = this.validator.validate(cost);

        this.setState({ validation: validation });

        if (validation.isValid)
            this.props.checkOrderWithCustomerReferenceExists(this.props.company.id, cost.orderNumber)
                .then((exists) => {
                    if (!exists) {
                        this.setState({ orderNumberError: true });
                        return;
                    } else {
                        this.setState({ confirmed: true, orderNumberError: false });
                    }
                })
                .catch(() => { });
    }

    back = () => {
        this.setState({ confirmed: false });
    }

    confirm = () => {
        this.setState({ loading: true });

        const creditNote = this.state.creditNote;

        this.props.createCreditNote(creditNote)
            .then((result) => {
                this.setState({
                    created: true,
                    error: false,
                    loading: false,
                    message: result.data
                });
            })
            .catch(() => {
                this.setState({
                    created: false,
                    error: true,
                    loading: false
                });
            });
    }

    return = () => {
        this.props.history.push(`/companiesforcredit/${encodeStringForURL(this.props.company.name.toLowerCase())}/creditactions/creditnotes`);
    }

    render() {
        const { creditNote, confirmed, created, error, loading, message, orderNumberError, validation } = this.state;

        return (
            <Container fluid>
                <Row>
                    <Col md={5}>
                        <h2 className="mb-4">Credit Notes</h2>

                        {error ? <React.Fragment >
                            <ErrorAlert title="There seems to be a problem" message="Credit note generation failed." />
                            <Button className="btn btn-primary float-right" onClick={this.return}>Return to Company Profile</Button>
                        </React.Fragment> :

                            created ?
                                <React.Fragment>
                                    <SuccessAlert title="Success" message={message} />
                                    <Button className="btn btn-primary float-right" onClick={this.return}>Return to Company Profile</Button>
                                </React.Fragment> :

                                <React.Fragment>
                                    <div className="form-input-description">
                                        <p className="mb-4">Once you have generated a credit note finance will be informed via email. Do not add credit to a customers account without notes.</p>
                                    </div>

                                    {loading ? <LoadingBar /> :
                                        <Form>
                                            {orderNumberError && <React.Fragment >
                                                <ErrorAlert title="There seems to be a problem" message="An order with this order number does not exist." />
                                            </React.Fragment>}

                                            <fieldset>
                                                <Col md={6} className="p-0 m-0">
                                                    {confirmed ?
                                                        <React.Fragment>
                                                            <h5><strong>Confirm Credit Note</strong></h5>
                                                            <FormGroup>
                                                                <FormLabel><strong>Company</strong></FormLabel>
                                                                <p>{this.props.company.name}</p>
                                                            </FormGroup></React.Fragment>
                                                        : null
                                                    }

                                                    <FormGroup>
                                                        <FormLabel htmlFor="amount"><strong>Credit Amount (£)</strong></FormLabel>
                                                        {confirmed ? <p>£{parseFloat(creditNote.amount).toFixed(2)}</p> :
                                                            <FormControl id="amount" type="number" name="amount" value={creditNote.amount} onChange={this.handleChange}
                                                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />}
                                                        <span className="text-danger">{validation.amount.message}</span>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <FormLabel htmlFor="orderNumber"><strong>Order Number</strong></FormLabel>
                                                        {confirmed ? <p>{creditNote.orderNumber}</p> :
                                                            <FormControl id="orderNumber" type="text" name="orderNumber" value={creditNote.orderNumber} onChange={this.handleChange} />}
                                                        <span className="text-danger">{validation.orderNumber.message}</span>
                                                    </FormGroup>
                                                </Col>

                                                <FormGroup>
                                                    <FormLabel htmlFor="notes"><strong>Reason for Credit Note</strong></FormLabel>
                                                    {confirmed ? <p className="text-break">{creditNote.notes}</p> :
                                                        <textarea className="form-control" rows="4" id="notes" type="textarea" name="notes" value={creditNote.notes} onChange={this.handleChange} />}
                                                    <span className="text-danger">{validation.notes.message}</span>
                                                </FormGroup>
                                            </fieldset>

                                            {confirmed ?
                                                <React.Fragment>
                                                    <Button className="float-left mt-4" variant="secondary" onClick={this.back}>Back</Button>
                                                    <Button className="float-right mt-4" onClick={this.confirm}>Confirm Credit Note</Button>
                                                    <Button className="float-right mt-4" variant="link" onClick={this.cancel}>Cancel</Button>
                                                </React.Fragment> :

                                                <React.Fragment>
                                                    <Button className="float-right mt-4" onClick={this.submit}>Submit Credit Note</Button>
                                                    <Button className="float-right mt-4" variant="link" onClick={this.cancel}>Cancel</Button>
                                                </React.Fragment>
                                            }
                                        </Form>
                                    }
                                </React.Fragment>
                        }
                    </Col>
                </Row>
            </Container >
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.company,
        companyUser: state.companyReducer.companyUser,
        user: state.userReducer.user
    }
}

const mapDispatchToProps = {
    createCreditNote: companyActions.createCreditNote,
    checkOrderWithCustomerReferenceExists: companyActions.checkOrderWithCustomerReferenceExists
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCreditNote)