const USER_API = process.env.REACT_APP_USER_API;
const RETAILER_API = process.env.REACT_APP_RETAILER_API;
const INTERNAL_API = process.env.REACT_APP_SELAZAR_API;

const CommonEndpoints = {
    LOGIN: USER_API + 'api/user/systemlogin',
    GET_USER_BY_ID: USER_API + 'api/user/getbyid/',

    ADDRESS: {
        GET: {
            COMPANY_BILLING: USER_API + 'api/address/getbillingaddressbycompanyid/'
        },
        PUT: {
            UPDATE_BILLING_ADDRESS: USER_API + 'api/address/updatebillingaddress'
        }
    },
    CHARGING: {
        POST : {
            CREATE_AD_HOC: INTERNAL_API + 'api/charging/generateadhocchargeforcompany'
        }
    },
    COMPANY: {
        GET: {
            ALL: USER_API + "api/company/getretailerswithcontacts",
            ALL_WITH_ACCESS: RETAILER_API + 'api/securitypreference/companieswithsupportaccess',
            BY_ID: USER_API + 'api/company/getbyid/',
            ACCESS: RETAILER_API + 'api/securitypreference/companysupportaccess/'
        },
        PUT: {
            UPDATE_COMPANY_NAME: USER_API + 'api/company/updatename'
        },
        POST: {
            SEND_CODE: USER_API + 'api/company/createandsendverificationcode',
            VERIFY_CODE: USER_API + "api/company/verifycode",
            TEMPORARY_LOGIN_DETAILS: USER_API + 'api/support/impersonate/company/',
        }
    },
    CREDIT: {
        GET: {
            ALL: INTERNAL_API + 'api/creditnote/getcreditnotes/',
            CREDIT_NOTES_BY_ID: INTERNAL_API + 'api/creditnote/byorderid',
            TOTAL_CREDIT: INTERNAL_API + 'api/creditnote/totalcreditamount',
            LAST_ISSUE_DATE: INTERNAL_API + 'api/creditnote/getlatestcreditissuedatestring'
        },
        POST : {
            CREATE_CREDIT_NOTE: INTERNAL_API + 'api/creditnote/create'
        }
    },
    FINANCE: {
        GET: {
            CARD_DETAILS: USER_API + 'api/carddetails/getbycompanyid/',
            DIRECT_DEBIT: USER_API + 'api/companydirectdebit/getforcompany/'
        },
        PUT: {
            DIRECT_DEBIT: USER_API + 'api/companydirectdebit/updateforcompany'
        }
    },
    INVOICE: {
        GET: {
            BY_ID: INTERNAL_API + 'api/invoice/getforcompanycustomerservice/', 
            TYPES: INTERNAL_API + 'api/invoice/gettypes'
        }
    },
    ORDER: {
        GET: {
            PROCESSING: RETAILER_API + 'api/order/inprocess/',
            COMPLETED: RETAILER_API + 'api/order/completed/',
            FAILED: RETAILER_API + 'api/order/failed/',
            COSTS: RETAILER_API + 'api/order/getordercostbreakdown/',
            TRACKING_URL_ROOT: RETAILER_API + 'api/order/trackingrooturlbyproviderid/',
            ORDER_WITH_CUSTOMER_REF: INTERNAL_API + 'api/order/orderexistswithcustomerreference'
        }
    },
    PASSWORD_RESET: {
        GET: {
            BY_ID: USER_API + 'api/passwordresetrequest/getliverequestbyid/'
        },
        PUT: {
            CHANGE_PASSWORD: USER_API + 'api/passwordresetrequest/changepassword',
            CHANGE_PASSWORD_CUSTOMER_SERVICE: USER_API + 'api/passwordresetrequest/changepasswordforcustomerservice',
            RESET_PASSWORD: USER_API + 'api/passwordresetrequest/resetpassword/'
        }
    },
    USER: {
        GET: {
            FILTERED_USERS: USER_API + 'api/user/forcustomerservice/'
        },
        PUT: {
            UPDATE_EMAIL: USER_API + 'api/contact/UpdateEmail'
        }
    }
}

export default CommonEndpoints;