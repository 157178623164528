import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { userActions } from '../actions/userActions';
import { companyActions } from '../actions/companyActions';

import './Header.scss';

class Header extends Component {
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.companyLogout = this.companyLogout.bind(this);
    }

    logout() {
        this.props.logout()
            .then(this.props.onLogout());
    }

    companyLogout() {
        if (this.props.company.isAuth) {
            if (window.confirm(`Are you sure you want to log out of ${this.props.company.name}? To log back into this account you will need to go through the verification process again.`)) {
                window.location = "/companies"
            }
        } else {
            window.location = '/';
        }
    }

    render() {
        return (
            <Navbar className="shadow-sm fixed-top">
                <Navbar.Brand className="mx-4" onClick={() => { this.companyLogout(); }}>
                    <img alt="Selazar Logo" src="/Images/logo.png" className="d-inline-block align-top" />
                </Navbar.Brand>

                <Nav className="ml-auto">
                    <Nav.Link onClick={() => { if (window.confirm('Are you sure you wish to log out?')) this.logout(); }}>
                        Sign Out <FontAwesomeIcon className="ml-1" icon={faSignOutAlt} />
                    </Nav.Link>
                </Nav>
            </Navbar>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuth: state.companyReducer.isAuth,
        company: state.companyReducer.company
    }
}

const mapDispatchToProps = {
    logout: userActions.logout,
    resetVerifiedCompantyTimeout: companyActions.resetVerifiedCompantyTimeout
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)