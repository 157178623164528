import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { encodeStringForURL } from '../../../Utilities';
import { extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Confirm from '../../common/Confirm';
import { ErrorAlert } from '../../common/Alerts';
import { SubNav, CompanyDetails, CompanyOwnerDetails, CompanyBillingDetails, CardDetails, PaymentTerms, EnablePaymentModal } from './Snippets';

import { companyActions } from '../../../actions/companyActions';

const DISABLE_MODAL_TITLE = "Disable Payment Terms Permission";

const getDisablePermissionModalMessage = (name) => `Are you sure you want to disable ${name} payment terms permission? This will revert the customer to a daily collection schedule and remove any existing bank account as their payment method.`;

class CompanyProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            name: '',
            joinedDate: '',
            forename: '',
            surname: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            town: '',
            country: '',
            county: '',
            postcode: '',
            holderName: '',
            cardNumber: '',
            status: true,
            debitDetails: {
                companyID: this.props.company.id,
                enabled: false,
                paymentTerms: null
            },
            showDebitError: false,
            selectedPaymentTerms: null,
            showCompanyDetails: true,
            showEnablePaymentModal: false,
            showDisablePaymentModal: false
        }
    }

    componentDidMount() {
        const companyId = this.props.company.id;

        this.props.getCompanyBillingAddress(companyId)
            .then((result) => {
                var billingDetails = extractData(result);
                this.setState({
                    addressLine1: billingDetails.line1,
                    addressLine2: billingDetails.line2,
                    town: billingDetails.town,
                    country: billingDetails.country,
                    county: billingDetails.county,
                    postcode: billingDetails.postcode
                });
            })
            .then(() => {
                this.props.getCompanyCardDetails(companyId)
                    .then((result) => {
                        var cardDetails = extractData(result);
                        this.setState({
                            holderName: cardDetails.holderName,
                            cardNumber: `**** **** **** ${cardDetails.cardSummary}`,
                            status: cardDetails.pendingAuthorisation
                        });
                    })
                    .catch(() => { });
            })
            .then(() => {
                this.props.getCompanyDirectDebitDetails(companyId)
                    .then((result) => {
                        var debitDetails = extractData(result);
                        if (debitDetails !== null) this.setState({ debitDetails: debitDetails });
                        this.setState({ showDebitError: false });
                    })
                    .catch((e) => {
                        console.log(e);
                        this.setState({ showDebitError: true });
                    });
            })
            .catch((e) => {
                console.log(e);
                this.setState({ showDebitError: true });
            });
    }

    enableCompanyDirectDebit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { debitDetails, selectedPaymentTerms } = this.state;

        const model = {
            companyId: debitDetails.companyID,
            enabled: true,
            paymentTerms: selectedPaymentTerms ? selectedPaymentTerms : 0
        }

        this.props.updateCompanyDirectDebitDetails(model)
            .then((result) => {
                var debitDetails = extractData(result);
                if (debitDetails !== null) this.setState({ debitDetails: debitDetails });
                this.setState({ loading: false, showEnablePaymentModal: false });
            })
            .catch((e) => {
                console.log(e);
                this.setState({ loading: false, showEnablePaymentModal: false });
            });
    }

    disableCompanyDirectDebit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { debitDetails } = this.state;

        const model = {
            companyId: debitDetails.companyID,
            enabled: false,
            paymentTerms: null
        }

        this.props.updateCompanyDirectDebitDetails(model)
            .then((result) => {
                var debitDetails = extractData(result);
                if (debitDetails !== null) this.setState({ debitDetails: debitDetails })
                this.setState({ loading: false, showDisablePaymentModal: false });
            })
            .catch((e) => {
                console.log(e);
                this.setState({ loading: false, showDisablePaymentModal: false });
            });
    }


    onNavClick = () => this.setState(prevState => ({ showCompanyDetails: !prevState.showCompanyDetails }));

    onEnableModalShowHide = () => this.setState(prevState => ({ showEnablePaymentModal: !prevState.showEnablePaymentModal }));
    onEnableClose = () => {
        this.setState({ selectedPaymentTerms: null });
        this.onEnableModalShowHide();
    };

    handleEnableInputChange = (paymentTerm) => {
        this.setState({ selectedPaymentTerms: paymentTerm });
    }

    onDisableModalShowHide = () => this.setState(prevState => ({ showDisablePaymentModal: !prevState.showDisablePaymentModal }));

    render() {
        const { loading, showCompanyDetails, showEnablePaymentModal, showDisablePaymentModal, showDebitError, addressLine1, addressLine2, town, county, postcode, holderName, cardNumber, status, debitDetails, selectedPaymentTerms } = this.state;

        const { name, createdDate } = this.props.company;
        const { forename, surname, email } = this.props.companyUser;

        return (
            <Container fluid>
                <Link className="link mr-1" to={'/companies'}>Companies</Link>
                <FontAwesomeIcon icon={faAngleRight} />
                <Link className="link mx-1" to={`/companies/${encodeStringForURL(this.props.company.name.toLowerCase())}`}>{this.props.company.name}</Link>
                <FontAwesomeIcon className="mr-1" icon={faAngleRight} />Company Profile

                {loading ? <LoadingBar />
                    : <React.Fragment>
                        <h2 className="d-block mt-4 mb-4">Company Profile</h2>
                        <SubNav showCompanyDetails={showCompanyDetails} onNavClick={this.onNavClick} />
                        <h4 className="mb-3">Company Details</h4>
                        {showCompanyDetails
                            ? <React.Fragment>
                                <CompanyDetails name={name} createdDate={createdDate} />
                                <CompanyOwnerDetails forename={forename} surname={surname} email={email} name={name} />
                            </React.Fragment>
                            : <React.Fragment>
                                <CompanyBillingDetails addressLine1={addressLine1} addressLine2={addressLine2} town={town} count={county} postcode={postcode} name={name} />
                                <CardDetails holderName={holderName} cardNumber={cardNumber} status={status} />
                                {showDebitError
                                    ? <Row>
                                        <Col sm={12} md={6}>
                                            <ErrorAlert message="Unable to retrieve direct debit details. Please try again." />
                                        </Col>
                                    </Row>
                                    : <PaymentTerms directDebitEnabled={debitDetails.enabled} paymentTerm={debitDetails.paymentTerms} showDebitModal={debitDetails.enabled ? this.onDisableModalShowHide : this.onEnableModalShowHide} />}
                                {showEnablePaymentModal && <EnablePaymentModal handleClose={this.onEnableClose} handleConfirmClose={this.onEnableClose} handleConfirmAction={this.enableCompanyDirectDebit} paymentTerms={selectedPaymentTerms ? selectedPaymentTerms : debitDetails.paymentTerms} handleInputChange={this.handleEnableInputChange} />}
                                {showDisablePaymentModal && <Confirm title={DISABLE_MODAL_TITLE} text={getDisablePermissionModalMessage(name)} buttonText="Disable" buttonVariant="danger" cancelText="Cancel Editing" handleConfirmClose={this.onDisableModalShowHide} handleClose={this.onDisableModalShowHide} handleConfirmAction={this.disableCompanyDirectDebit} />}
                            </React.Fragment>}
                    </React.Fragment>}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyReducer.verifiedCompany,
        companyBillingAddress: state.companyReducer.companyBillingAddress,
        companyUser: state.companyReducer.companyUser
    }
}

const mapDispatchToProps = {
    getCompanyBillingAddress: companyActions.getCompanyBillingAddress,
    getCompanyCardDetails: companyActions.getCompanyCardDetails,
    getCompanyDirectDebitDetails: companyActions.getCompanyDirectDebitDetails,
    updateCompanyDirectDebitDetails: companyActions.updateCompanyDirectDebitDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile)