import * as actionType from '../actionTypes/userActionTypes';

const initState = {
    user: {
        forename: '',
        surname: ''
    },
    error: ''
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case actionType.USER_LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                error: ''
            }
        case actionType.USER_GET_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    forename: action.user.forename,
                    surname: action.user.surname
                },
                error: ''
            }
        case actionType.USER_GET_FILTERED_FOR_COMPANY_SUCCESS:
            return {
                ...state,
                error: ''
            }
        case actionType.USER_GET:
        case actionType.USER_GET_FILTERED_FOR_COMPANY:
            return {
                ...state,
                username: action.user
            }
        case actionType.USER_GET_FAILURE:
        case actionType.USER_LOGIN_FAILURE:
        case actionType.USER_GET_FILTERED_FOR_COMPANY_FAILURE:
            return {
                ...state,
                error: action.error
            }
        default:
            return state;
    }
}

export default userReducer;