import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';

import { Layout } from './components/Layout';
import PrivateRoute from './PrivateRoute';
import Login from './components/Login';
import ForgotPassword from './components/account/password/ForgotPassword';
import ResetPassword from './components/account/password/ResetPassword';
import ResetResult from './components/account/password/ResetResult';

import NotFound from './components/common/NotFound';
import Dashboard from './components/Dashboard';

import CompaniesForCredit from './components/creditActions/CompaniesForCredit';
import CreditActions from './components/creditActions/CreditActions';
import AdditionalCosts from './components/creditActions/AdditionalCosts';
import CreditNotes from './components/creditActions/CreditNotes';
import CreateCreditNote from './components/creditActions/CreateCreditNote';
import ViewCreditNotes from './components/creditActions/ViewCreditNotes';
import CreditNoteDetails from './components/creditActions/CreditNoteDetails';

import Companies from './components/company/Companies';
import CompanyVerificationStepOne from './components/company/verification/CompanyVerificationStepOne';
import CompanyVerificationStepTwo from './components/company/verification/CompanyVerificationStepTwo';
import CompanyVerificationStepThree from './components/company/verification/CompanyVerificationStepThree';

import CompanyDashboard from './components/company/CompanyDashboard';
import CompanyProfile from './components/company/profile/CompanyProfile';
import CompanyProfileEdit from './components/company/profile/CompanyProfileEdit';
import CompanyOrderHistory from './components/company/orderHistory/CompanyOrderHistory';
import CompanyCompletedOrderHistory from './components/company/orderHistory/CompanyCompletedOrderHistory';
import CompanyFailedOrderHistory from './components/company/orderHistory/CompanyFailedOrderHistory';
import CompanyOrderHistoryDetails from './components/company/orderHistory/CompanyOrderHistoryDetails';
import CompanyAccess from './components/company/access/CompanyAccess';
import Invoicing from './components/company/invoices/Invoicing';
import Invoices from './components/company/invoices/Invoices';
import Settings from './components/company/settings/Settings';
import Security from './components/company/settings/Security';
import ChangePassword from './components/company/settings/ChangePassword';

import './App.css';

class App extends Component {
  displayName = App.name;

  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false
    }
  }

  isLoggedIn = () => {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      return true;
    } else {
      return false;
    }
  }

  onLogin = (data) => {
    if (data.token) {
      localStorage.setItem('user', JSON.stringify(data));

      this.setState({
        loggedIn: true
      });
    }
  }

  onLogout = () => {
    this.setState({
      loggedIn: false
    });
  }

  renderLogin() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path='/' render={() => (<Login onLogin={this.onLogin} />)} />

          <Route exact path='/account/password/forgot' component={ForgotPassword} />
          <Route exact path='/account/password/result' component={ResetResult} />
          <Route exact path='/account/password/reset/:id' component={ResetPassword} />
        </Switch>
      </React.Fragment>
    );
  }

  renderHome() {
    return (
      <Layout onLogout={this.onLogout}>
        <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route exact path='/companies' component={Companies} />
          <Route exact path='/companies/:name/verify/step1' component={CompanyVerificationStepOne} />
          <Route exact path='/companies/:name/verify/step2' component={CompanyVerificationStepTwo} />
          <Route exact path='/companies/:name/verify/step3' component={CompanyVerificationStepThree} />

          <Route exact path='/companiesforcredit' component={CompaniesForCredit} />
          <Route exact path='/companiesforcredit/:name/creditactions' component={CreditActions} />
          <Route exact path='/companiesforcredit/:name/creditactions/additionalcosts' component={AdditionalCosts} />
          <Route exact path='/companiesforcredit/:name/creditactions/creditnotes' component={CreditNotes} />
          <Route exact path='/companiesforcredit/:name/creditactions/creditnotes/create' component={CreateCreditNote} />
          <Route exact path='/companiesforcredit/:name/creditactions/creditnotes/view' component={ViewCreditNotes} />
          <Route exact path='/companiesforcredit/:name/creditactions/creditnotes/view/:id' component={CreditNoteDetails} />

          <PrivateRoute exact path='/companies/:name' component={CompanyDashboard} />
          <PrivateRoute exact path='/companies/:name/profile' component={CompanyProfile} />
          <PrivateRoute exact path='/companies/:name/profile/edit/:detail' component={CompanyProfileEdit} />
          <PrivateRoute exact path='/companies/:name/orderhistory/processing' component={CompanyOrderHistory} />
          <PrivateRoute exact path='/companies/:name/orderhistory/completed' component={CompanyCompletedOrderHistory} />
          <PrivateRoute exact path='/companies/:name/orderhistory/failed' component={CompanyFailedOrderHistory} />
          <PrivateRoute exact path='/companies/:name/orderhistory/:id' component={CompanyOrderHistoryDetails} />
          <PrivateRoute exact path='/companies/:name/invoicing' component={Invoicing} />
          <PrivateRoute exact path='/companies/:name/invoicing/invoices' component={Invoices} />
          <PrivateRoute exact path='/companies/:name/settings' component={Settings} />
          <PrivateRoute exact path='/companies/:name/settings/security' component={Security} />
          <PrivateRoute exact path='/companies/:name/settings/security/:id' component={ChangePassword} />
          <Route exact path='/companies/:name/access' component={CompanyAccess} />

          <Route component={NotFound} />
        </Switch>
      </Layout>
    );
  }

  render() {
    const result = this.isLoggedIn();
    const contents = result ? this.renderHome() : this.renderLogin();

    return (
      <React.Fragment>
        {contents}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.companyReducer.company,
    user: state.userReducer.user,
    verifiedCompanyTimeout: state.companyReducer.verifiedCompanyTimeout
  }
}

export default connect(mapStateToProps, null)(App)