import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Container, Form, FormControl, FormGroup, FormLabel, Image, Row } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { extractData } from '../Consumer';

import { userActions } from '../actions/userActions';

import './Login.scss';

const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            loginError: '',
            password: '',
            passwordInputIcon: faEye,
            passwordInputType: 'password',
            recaptcha: false,
            recaptchaError: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleShowHidePassword = this.handleShowHidePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    handleRecaptcha = () => {
        this.setState({ recaptcha: true });
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let { recaptcha, email, password } = this.state;

        this.setState({ recaptchaError: !recaptcha });

        if (this.state.recaptcha) {
            this.props.login(email, password)
                .then((result) => {
                    this.props.onLogin(extractData(result))
                })
                .catch((error) => {
                    this.setState({ loginError: error.message });
                });
        }
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    render() {
        let { recaptchaError } = this.state;

        return (
            <Container className="h-100">
                <Row className="justify-content-center row-form-adjustedheight">
                    <Col md={6} className="align-self-center">
                        <Card className="p-5">
                            <Image src='Images/logo.png' className="mx-auto mb-2" width="220px" />
                            <h5 className="mb-4 mt-3 text-center">Customer Service Portal</h5>

                            <Form onSubmit={this.handleSubmit}>
                                {this.state.loginError && <Card className="card-danger mb-3">
                                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                                    <Card.Body>{this.state.loginError}</Card.Body>
                                </Card>}
                                <FormGroup>
                                    <FormLabel className="font-weight-bold">Email</FormLabel>
                                    <FormControl autoFocus type="email" id="email" value={this.state.email} onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup className="mb-1">
                                    <FormLabel className="font-weight-bold">Password</FormLabel>
                                    <FormControl type={this.state.passwordInputType} id="password" value={this.state.password} onChange={this.handleChange} />
                                    <span className="form-password-icon" onClick={this.handleShowHidePassword}>
                                        <FontAwesomeIcon icon={this.state.passwordInputIcon} /></span>
                                </FormGroup>
                                <FormGroup>
                                    <Link to='/account/password/forgot'>Forgot Password?</Link>
                                </FormGroup>
                                <Container className="d-flex justify-content-center my-4">
                                    <ReCAPTCHA sitekey={captchaKey} onChange={this.handleRecaptcha} />
                                </Container>
                                {recaptchaError && <p className="text-danger">Please complete captcha</p>}

                                <Button size="lg" type="submit" block>Login</Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapDispatchToProps = {
    login: userActions.login
}

export default connect(null, mapDispatchToProps)(Login)