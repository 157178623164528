import React, { Component } from 'react';
import { NavItem } from 'react-bootstrap';
import { checkPermission } from './../Utilities';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCreditCard } from '@fortawesome/free-solid-svg-icons';

import './NavMenu.scss';

export class NavMenu extends Component {
    render() {
        const SideNavItem = ({ link, icon, title, exactLink = false }) => {
            return (
                <LinkContainer to={link} exact={exactLink}>
                    <NavItem><FontAwesomeIcon className="mr-1" icon={icon} /> {title}</NavItem>
                </LinkContainer>
            );
        };

        const CompaniesNav =
            (<React.Fragment>
                {checkPermission('ViewCustomerCareDashboard', <SideNavItem link="/companies" icon={faBuilding} title="Companies" />)}
                {checkPermission('CustomerServiceCreateAdHocCharge', <SideNavItem link="/companiesforcredit" icon={faCreditCard} title="Credit Actions" />)}
            </React.Fragment>);

        return (
            <div className="sidebar-wrapper py-4">
                {CompaniesNav}
            </div>
        );
    }
}